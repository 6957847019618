import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA1hN4CxDMfpHQk63MNsCuKAXYx1CauXM4",
  authDomain: "umojasc-79323.firebaseapp.com",
  projectId: "umojasc-79323",
  storageBucket: "umojasc-79323.appspot.com",
  messagingSenderId: "407066840158",
  appId: "1:407066840158:web:c4ab880b5ad68e297ef8cf",
  measurementId: "G-K9R8G37WK7"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();
